import {
  Box,
  CSSReset,
  Flex,
  Link,
  Text,
  theme,
  ThemeProvider
} from "@chakra-ui/core";
import React from "react";
import "./App.css";
import Header from "./containers/Header";
import {
  BasketContext,
  DeliveryContext,
  useBasket,
  useDelivery
} from "./store";
import Contact from "./views/Contact";

const Home = () => {
  return (
    <Flex as="main" backgroundColor="red.600" justify="center">
      <Flex
        align="stretch"
        direction="column"
        justify="space-between"
        minHeight="calc(100vh - 92px)"
        paddingLeft={[4, 0, 0, 0]}
        paddingRight={[4, 0, 0, 0]}
        width={[
          "100%", // base
          "448px", // 480px upwards
          "448px", // 768px upwards
          "448px" // 992px upwards
        ]}
      >
        <Box mt={8} fontSize="sm" textAlign="justify">
          <Text fontWeight="bold">Hi folks,</Text>
          <Text mt={4}>
            It is with a heavy heart that I’m letting you know that all Monkey
            shops will be closing their doors today at 3pm.
          </Text>
          <Text mt={2}>
            But as one last hurrah we are excited to announce Free Food Friday.
          </Text>
          <Text mt={2}>
            We know this has been a tough week for everyone but we have been
            totally overwhelmed this week by the support we have been shown by
            so many loyal customers and we want to say thank you.
          </Text>
          <Text mt={2}>
            We would normally donate all our waste to Glasgow City Mission
            however after chatting with them today they are at capacity and are
            currently stocking their freezers.
          </Text>
          <Text mt={2}>
            But we can’t stand the waste so please folks if you are in town
            today come on by for some free food. We’d love to see you.
          </Text>
          <Text mt={2}>
            We’ve made the decision to close because we want to keep our staff
            and customers safe. We want to play our part in the national effort
            to combat this virus and we feel like this is the only way to do
            that.
          </Text>
          <Text mt={2}>
            But don’t fear we have every intention and are confident that The
            Monkey will be back and better than ever once we are on the other
            side of the crisis.
          </Text>
          <Text mt={2}>
            In the meantime while we are closing our physical doors we are
            opening our virtual one so if you’d like to get yourself some monkey
            merch or join our Tasty Tuesday club please check out{" "}
            <Link href="https://shop.monkeysleeps.com">
              shop.monkeysleeps.com
            </Link>
            .
          </Text>
          <Text mt={2}>
            We hope to see you today for some tasty sandwiches and please
            everyone keep safe and look after each other.
          </Text>
          <Text mt={4} fontWeight="bold">
            Sofie and Team Monkey
          </Text>
        </Box>
        <Contact />
      </Flex>
    </Flex>
  );
};

const App = () => {
  const basket = useBasket();
  const delivery = useDelivery();

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <BasketContext.Provider value={basket}>
        <DeliveryContext.Provider value={delivery}>
          <Header />
          <Home />
          {/* <Menu path={constants.menuRoute} /> */}
          {/* <CheckoutSuccess path={constants.checkoutSuccessRoute} /> */}
          {/* <Checkout path={constants.checkoutRoute} /> */}
        </DeliveryContext.Provider>
      </BasketContext.Provider>
    </ThemeProvider>
  );
};

export default App;
