import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

Sentry.init({
  dsn: "https://9bed012ddb00436bb8e111f9fe83b1bd@sentry.io/4817191"
});

ReactDOM.render(<App />, document.getElementById("root"));
